exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-changelog-index-js": () => import("./../../../src/pages/changelog/index.js" /* webpackChunkName: "component---src-pages-changelog-index-js" */),
  "component---src-pages-changelog-lite-js": () => import("./../../../src/pages/changelog/lite.js" /* webpackChunkName: "component---src-pages-changelog-lite-js" */),
  "component---src-pages-changelog-pc-js": () => import("./../../../src/pages/changelog/pc.js" /* webpackChunkName: "component---src-pages-changelog-pc-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-android-js": () => import("./../../../src/pages/install/android.js" /* webpackChunkName: "component---src-pages-install-android-js" */),
  "component---src-pages-install-pc-js": () => import("./../../../src/pages/install/pc.js" /* webpackChunkName: "component---src-pages-install-pc-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-lite-js": () => import("./../../../src/pages/products/lite.js" /* webpackChunkName: "component---src-pages-products-lite-js" */),
  "component---src-pages-products-pc-js": () => import("./../../../src/pages/products/pc.js" /* webpackChunkName: "component---src-pages-products-pc-js" */)
}

